<template lang="pug">
div
  div.content(v-bind:class="{smallScreen:isSmallScreen}")
    a#logo_container(href="/")
      img.logo(:src="logo")
    div(v-if="!token")
      div.title Bring your remote team closer together with WishWell
      div.subtitle
        span Send group videos of your team welcoming a new teammate, celebrating a birthday, or saying farewell.
        span Build a happier team with more team appreciation, all in slack!
      a.install_slack.noselect(:href="slackButtonLink")
        img.slack_logo(src="https://wishwell-site-content.s3.amazonaws.com/slack-logo-icon.svg")
        span Add WishWell to Slack
      div.clear
      div.how_does_it_work
        div.thumbnail(v-on:click="play")
          div.play_button
            img(:src="playIconSrc" style="touch-action: manipulation" title="play videos")
          video(
            playsinline
            loop
            muted
            autoplay
            :src="explainerVideoSrc"
          )
        div.preview_title See how it works
    div#select_slack_channel(v-if="token")
      div.join_channel_cta
        span Select the slack channels you want WishWell to join
        br
        span.details The bot will only interact with members of those channels
      div.loading(v-show="loading")
        img(v-bind:src="loadingSrc")
        div.darkener
      div.join
        Multiselect(
            v-model="selectedChannel"
            :options="channels"
            label="name"
            track-by="id"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Select slack channel"
            :allow-empty="false"
          )
      div.buttons
        button.cta(v-on:click="joinChannel") Join Channel
  img.moon(v-if="!token" src="https://wishwell-site-content.s3.amazonaws.com/moon.svg")
  img.planet(v-if="!token" src="https://wishwell-site-content.s3.amazonaws.com/planet_rover.svg")
  img.background(src="https://wishwell-site-content.s3.amazonaws.com/space_bg.jpg")
  a.credits(href="/credits") credits
  div.movie_container(v-if="!token && showExplainer")
    div.wrapper
      div.play_button(v-show="showPlayButton" v-on:click="togglePlay")
        img(:src="playIconSrc" style="touch-action: manipulation" title="play videos")
      video#explainer_video(
        playsinline
        loop
        :src="explainerVideoSrc"
        v-on:click="togglePlay"
      )
      div.hider(v-on:click="hide")
</template>

<script>
/* global gtag */

import Header from '../components/Header'
import Multiselect from 'vue-multiselect'
import config from '../appConfig'
import { GET_SLACK_CHANNELS_QUERY } from '../graphql/queries'
import { JOIN_SLACK_CHANNEL_MUTATION } from '../graphql/mutations'

export default {
  name: 'Slack',
  metaInfo() {
    return {
        title: `WishWell for Work`,
        meta: [
          {property: 'og:title', content: `Bring your remote team closer together`},
          {property: 'og:description', content: `Build a happier, better connected team with a group video messaging bot in Slack`},
          {property: 'og:image', content: `https://wishwell.to/wwfw.png`},
          {property: 'og:video', content: `https://wishwell-site-content.s3.amazonaws.com/WishWell+Explainer+Video+-+lead+with+payoff.mp4`},
          {property: 'og:url', content: `https://wishwell.to/for/work`},
          {name: 'robots', content: 'index,follow'}
        ]
    }
  },
  props: {
    token: {
      type: String,
      default: null
    },
  },
  components: {
    Header,
    Multiselect,
  },
  watch: {
    channels: function () {
      if (this.channels.length > 0) gtag('event','installed_slack_bot')
    },
  },
  computed: {
    loadingSrc () {
      return process.env.BASE_URL + "loading.gif"
    },
    playIconSrc () {
      return process.env.BASE_URL + "play_icon_white.svg"
    },
    explainerVideoSrc () {
      return "https://wishwell-site-content.s3.amazonaws.com/WishWell+Explainer+Video+-+lead+with+payoff.mp4"
    },
    logo () {
      return process.env.BASE_URL + "logo.svg"
    },
    isSignedIn () {
      return this.$store.getters.signedIn
    },
    slackButtonLink () {
      return "https://slack.com/oauth/v2/authorize?scope="+process.env.VUE_APP_SLACK_SCOPES+"&client_id="+process.env.VUE_APP_SLACK_CLIENT_ID+"&redirect_uri="+process.env.VUE_APP_SLACK_REDIRECT_URL
    },
  },
  apollo: {
    channels () {
      return {
        // gql query
        query: GET_SLACK_CHANNELS_QUERY,
        // Static parameters
        variables () {
          return {
            authenticationToken: this.token
          }
        },
        update: data => {
          this.loading = false
          return data.slackChannels
        },
        skip () {
          return !this.token
        },
        fetchPolicy: 'network-only',
      }
    },
  },
  data () {
    return {
      selectedChannel: null,
      channels: [],
      isSmallScreen: config.isSmallScreen(),
      showPlayButton: true,
      showExplainer: false,
      loading: false,
    }
  },
  methods: {
    hide: function() {
      this.showExplainer = false
      this.pause()
    },
    togglePlay: function() {
      if (this.showPlayButton) {
        this.showPlayButton = false
        this.play()
      } else {
        this.showPlayButton = true
        this.pause()
      }
    },
    pause: function() {
      var video = document.getElementById("explainer_video")
      video.pause()
      this.showPlayButton = true
    },
    play: async function () {
      var video = document.getElementById("explainer_video")
      this.showExplainer = true
      try {
        await video.play()
        this.showPlayButton = false
      } catch(err) {
        this.pause()
      }
    },
    onResize() {
      this.isSmallScreen = config.isSmallScreen()
    },
    joinChannel: function() {
      if (!this.token) return
      if (!this.selectedChannel) {
        this.$toast.error('Please select a channel.')
        return
      }

      this.loading = true
      this.$apollo.mutate({
        mutation: JOIN_SLACK_CHANNEL_MUTATION,
        variables: {
          authenticationToken: this.token,
          channelId: this.selectedChannel.id,
        }
      }).then((data) => {
        // console.log(data)
        const success = data.data.slackJoinChannel.success
        const errors = data.data.slackJoinChannel.errors
        const channel = data.data.slackJoinChannel.channel
        this.loading = false

        if (errors) {
          this.errorsToast(errors)
          gtag('event','error_joining_channel')
        } else if (success) {
          this.$toast.success('WishWell has successfully joined "'+channel["name"]+'."  You can add another channel or go to slack to interact with the bot.', {timeout: 7000})
          gtag('event','joined_slack_channel')
        }
      }).catch((error) => {
        // console.error(error)
        this.errorToast(error)
        this.loading = false
        gtag('event','error_joining_channel')
      })
    },
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize)
  },
  mounted: function() {
    document.dispatchEvent(new Event('pre-render-trigger'))
    window.addEventListener('resize', this.onResize)
    // window.vue = this
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/install_slack_bot'})
    gtag('event','view_page')

    this.loading = this.token != null
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#logo_container {
  display: block;
  cursor: pointer;
  margin-bottom: 60px;
  background-color: rgba(0,0,0,0);
}

.buttons {
  margin-top: 30px;
}

.content {
  position: relative;
  z-index: 100;
  width: 94%;
  height: 94%;
  padding: 3% 3%;
}

.slack_logo {
  position: absolute;
  left: 4px;
  top: 13%;
  width: 60px;
  height: 60px;
}

.smallScreen .slack_logo  {
  top: 6%;
}

.install_slack {
  position: relative;
  display: block;
  background-color: #ffffff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  font-family: gibsonsemibold;
  font-size: 32px;
  color: #11072F;
  padding: 24px 48px 24px 68px;
  float: left;
  text-decoration: none;
  margin: 20px 0px;
}

.smallScreen .install_slack {
  font-size: 18px;
  margin: 40px 0px 80px 0px;
}

a:hover {
  background-color: #efeff7;
}

.moon {
  position: fixed;
  top: 16%;
  right: 2%;
  z-index: 10;
  width: 40%;
  height: 40%;
}

.planet {
  position: fixed;
  bottom: -15%;
  right: 20%;
  z-index: 20;
  width: 40%;
  height: 40%;
}

.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover;
}

.label {
  display: block;
  background-color: #090c1a;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  font-family: gibsonsemibold;
  font-size: 18px;
  color: #ffffff;
  padding: 14px 24px;
  float: left;
  opacity: 0.75;
}

.title {
  font-family: gibsonsemibold;
  font-size: 60px;
  color: #ffffff;
  margin: 40px 0px 20px 0px;
  max-width: 65%;
}

.smallScreen .title {
  font-size: 40px;
  max-width: 80%;
  text-shadow: 0 2px 8px rgba(0,0,0,0.20);
}

.join_channel_cta {
  font-family: gibsonsemibold;
  font-size: 40px;
  color: #ffffff;
  margin: 40px 0px 30px 0px;
  max-width: 65%;
}

.details {
  font-family: gibsonregular;
  font-size: 22px;
}

.join .multiselect {
  max-width: 65%;
}

.subtitle {
  font-family: gibsonregular;
  font-size: 24px;
  color: #ffffff;
  margin: 20px 0px;
  max-width: 65%;
}

.smallScreen .subtitle {
  max-width: 80%;
  text-shadow: 0 2px 8px rgba(0,0,0,0.20);
}

.credits, a.credits:hover {
  display: block;
  z-index: 20;
  position: fixed;
  bottom: 14px;
  right: 38px;
  font-family: gibsonregular;
  font-size: 12px;
  color: #151F2F;
  background-color: rgba(0,0,0,0);
  text-decoration: none;
}

.how_does_it_work {
  position: relative;
  display: block;
  background-color: rgba(0, 0, 0, 0.60);
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  font-family: gibsonsemibold;
  font-size: 14px;
  color: #ffffff;
  /* padding: 24px 48px 24px 68px; */
  text-decoration: none;
  margin: 20px 0px 40px 0px;
  width: 280px;
  height: 160px;
}

.how_does_it_work video {
  cursor: pointer;
  object-fit: fill;
  width: 260px;
  height: 140px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 200;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  opacity: 0.8;
}

.preview_title {
  display: block;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: -28px;
  text-align: center;
  font-family: gibsonsemibold;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
}

.thumbnail {
  position: relative;
  height: 100%;
  width: 100%;
}

.hider {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.movie_container {
  display: block;
  z-index: 5000;
  position: fixed;
  top: 0px;
  left: 0px;;
  height: 100%;
  width: 100%;
  background-color: rgba(17, 17, 17, 0.8);
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.movie_container video {
  cursor: pointer;
  object-fit: fit;
  width: 85%;
  height: 85%;
  position: absolute;
  top: 7.5%;
  left: 7.5%;
  z-index: 200;
}

.play_button {
  position: absolute;
  z-index: 500;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-color: rgb(0,0,0,0.55);
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.how_does_it_work  .play_button {
  width: 60px;
  height: 60px;
}

.play_button:hover {
  background-color: rgb(0,0,0,0.35);
}

.play_button img {
  margin-left:8px;
  width: 28px;
  height: 32px;
}

.loading img {
  position: fixed;
  z-index: 500;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
}
.darkener {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:rgb(0,0,0,0.65);
}
</style>
